import { Button } from "flowbite-react";
import React from "react";
import { useTranslation } from "react-i18next";

import { HiOutlineArrowRight } from "react-icons/hi";
import { Link } from "react-router-dom";

interface ServiceCardInterface {
  title: string;
  description?: string;
  imageUrl: string;
}

function ServiceCard({ title, description, imageUrl }: ServiceCardInterface) {
  const {t} = useTranslation();
  
  return (
    <div className="flex flex-col items-center justify-between py-8 lg:py-12 gap-2 min-h-56">
      <img src={imageUrl} alt="hero-01.png" className="w-56 h-64 lg:w-[20rem] lg:h-[22rem]" />
      <h2 className="text-main-blue text-lg lg:text-2xl font-bold text-center">{title}</h2>
      {/* {description && (
        <h4 className="text-gray-600 text-center">{description}</h4>
      )} */}
      <Link to={"/services"}>
        <Button
          color={"main-red"}
          className="text-main-red bg-transparent 
        flex flex-col justify-center items-center font-bold
        hover:bg-main-red hover:text-white"
        >
          {t('learnMore')}
          <HiOutlineArrowRight className="ml-2 h-5 w-5" />
        </Button>
      </Link>
    </div>
  );
}

export default ServiceCard;
