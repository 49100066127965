import React, { useEffect, useState } from "react";
import { Button } from "flowbite-react";
import HeaderSpace from "../../components/Shared/HeaderSpace/HeaderSpace";
import Container from "../../components/Shared/Container/Container";
import StudentPreview from "../../components/Previews/StudentPreview";
import { Student } from "../../interfaces/Student";
import { collection, DocumentData, getDocs, QueryDocumentSnapshot } from "firebase/firestore";
import { db } from "../../firebase/firebaseConfig";
import Spinner from "../../components/Shared/Spinner/Spinner";
import { useTranslation } from "react-i18next"; // Translation hook

function Students() {
  const { t, i18n } = useTranslation(); // Initialize translation hook with i18n for language detection
  const [students, setStudents] = useState<Student[]>();

  useEffect(() => {
    const fetchData = async () => {
      const querySnapshot = await getDocs(collection(db, "students"));
      const items = querySnapshot.docs.map(
        (doc: QueryDocumentSnapshot<DocumentData>) =>
          ({
            id: doc.id,
            ...doc.data(),
          } as Student)
      );
      setStudents(items);
    };

    fetchData();
  }, []);

  if (!students) {
    return <Spinner />;
  }

  return (
    <div
      className="relative bg-repeat animate-breathing"
      style={{
        backgroundImage: `url('/images/backgrounds/background-dots-01.png')`,
        backgroundSize: "150px 150px",
      }}
    >
      <HeaderSpace />
      <Container className="py-4 lg:py-12">
        <div className="flex flex-col gap-16">
          <div className="flex flex-col text-center">
            <h3 className="text-main-blue font-bold">{t("successStories")}</h3>
            <h2 className="text-main-red font-bold text-4xl">{t("ourStudents")}</h2>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-16 lg:gap-32">
            <img
              src="/images/random/students-main.jpg"
              alt="about"
              className="lg:h-full object-cover rounded-xl"
            />
            <div className="flex flex-col gap-4">
              <h3
                className="font-bold text-main-blue text-center lg:text-left text-xl lg:text-3xl"
                dangerouslySetInnerHTML={{ __html: t("becomePartOfGraduates") }}
              />
              <h4 className="lg:text-left text-sm lg:text-[16px]">
                {t("ellTeamDescription")}
                <br />
                <br />
                {t("studentCountSince2002")}
                <br />
                <br />
                {t("proudAchievementDescription")}
              </h4>
              <Button
                className="bg-main-red"
                color={"main-red"}
                onClick={() => {
                  const element = document.getElementById("students-section");
                  element?.scrollIntoView({
                    behavior: "smooth",
                  });
                }}
              >
                {t("seeStudents")}
              </Button>
            </div>
          </div>
          <div className="flex flex-col gap-8" id="students-section">
            <h2 className="text-xl text-center lg:text-center lg:text-2xl font-bold text-main-red">
              {t("ourGraduates")}
            </h2>
            <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-7 gap-8">
              {students &&
                students.map((student) => {
                  const name = i18n.language === "en" ? student.nameEn || student.name : student.name; // Fallback to Georgian name if English is undefined
                  const description = i18n.language === "en" 
                    ? student.descriptionHTMLEn || student.descriptionHTML // Fallback to Georgian description if English is undefined
                    : student.descriptionHTML;

                  return (
                    <div className="flex items-center justify-center" key={student.id}>
                      <StudentPreview
                        id={student.id}
                        name={name}
                        imageUrl={student.imageUrl}
                        jobs={student.jobs}
                        descriptionHTML={description}
                      />
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default Students;
