import { Button, Card, Carousel } from "flowbite-react";
import Container from "../../Shared/Container/Container";
import HeaderSpace from "../../Shared/HeaderSpace/HeaderSpace";
import { HiOutlineArrowRight } from "react-icons/hi";
import { Link } from "react-router-dom";

function MainLanding() {
  return (
    <div
      className="relative bg-repeat animate-breathing" // Apply the custom animation class
      style={{
        backgroundImage: `url('/images/backgrounds/background-dots-01.png')`,
        backgroundSize: "150px 150px", // Initial size of the dots
      }}
    >
      <HeaderSpace />
      <Carousel draggable={false} slideInterval={7000} indicators={false} leftControl={""} rightControl={""}>
        <>
          <img
            src="/images/backgrounds/background-05-new_.png"
            alt="Main"
            className="absolute w-[100vw] hidden lg:flex lg:h-[80vh] left-0 object-cover scale-105 z-[0]"
          />
          <div className="relative flex items-center justify-center w-full h-[60vh] lg:h-[80vh] overflow-hidden my-4">
            <Container className="h-full flex flex-col justify-center">
              <Card
                className="max-w-xl relative rounded-xl h-[100%] px-4 py-8
              sm:py-8 lg:pb-8 lg:pt-12 shadow-2xl lg:h-fit lg:-translate-y-12 lg:translate-x-12"
              >
                <h4 className="text-main-red font-bold sm:text-xl lg:text-xl">
                  20 წელი თქვენთან ერთად
                </h4>
                <h1 className="text-main-blue font-extrabold text-3xl sm:text-5xl lg:text-5xl">
                  გახსენი შენი <br /> საზღვრები{" "}
                  <span className="text-main-red">დღესვე</span>{" "}
                </h1>
                <h5 className="text-gray-500 text-sm lg:text-lg">
                  მიაღწიე შენი თავის სრულ პოტენციალს საზღვარგარეთ. ჩვენ გაგიმარტივებთ საზღვარ გარეთ სწავლის უმთავრეს პროცესს:
                  სასურველი უნივერსიტეტის პოვნას, საელჩოში ვიზის განაცხადის შევსებას და შეხვედრის დაჯავშნას.
                </h5>
                <Link to={"/contact"}>
                  <Button color={"main-red"} className="py-1 w-fit px-4">
                    დაგვიკავშირდი
                    <HiOutlineArrowRight className="ml-2 h-5 w-5" />
                  </Button>
                </Link>
              </Card>
            </Container>
          </div>
        </>
        <div className="overflow-x-clip">
          <img
            src="/images/backgrounds/background-04-min.png"
            alt="Main"
            className="absolute w-[100vw] hidden lg:flex lg:h-[80vh] left-0 object-cover scale-105 z-[0]"
          />
          <div className="relative flex items-center justify-center w-full h-[60vh] lg:h-[80vh] overflow-hidden my-4">
            <Container className="h-full flex flex-col justify-center">
              <Card
                className="max-w-xl relative rounded-xl h-full px-4 py-8 
              sm:py-8 lg:pb-8 lg:pt-12 shadow-2xl lg:h-fit lg:-translate-y-12 lg:translate-x-12"
              >
                <h4 className="text-main-red font-bold sm:text-xl lg:text-xl">
                  ინგლისური ენის ცენტრი
                </h4>
                <h1 className="text-main-blue font-extrabold text-3xl sm:text-5xl lg:text-5xl">
                  ისწავლე ინგლისური ენა <br /> {" "}
                  <span className="text-main-red">მარტივად</span>{" "}
                </h1>
                <h5 className="text-gray-500 text-sm lg:text-lg">
                  ინგლისური ენის შემსწავლელი ინოვაციური კურსები მოზარდებისთვის და ზრდასრულებისთვის
                </h5>
                <Link to={"/contact"}>
                  <Button color={"main-red"} className="py-1 w-fit px-4">
                    დაგვიკავშირდი
                    <HiOutlineArrowRight className="ml-2 h-5 w-5" />
                  </Button>
                </Link>
              </Card>
            </Container>
          </div>
        </div>
      </Carousel>
    </div>
  );
}

export default MainLanding;
