import classNames from "classnames";
import React from "react";

interface Wave01Props {
    className?: string;
}

function Wave01({className}: Wave01Props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" className={classNames(className)}>
      <path
        fill="#E22140"
        fill-opacity="1"
        d="M0,128L48,154.7C96,181,192,235,288,256C384,277,480,267,576,266.7C672,267,768,277,864,256C960,235,1056,181,1152,176C1248,171,1344,213,1392,234.7L1440,256L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
      ></path>
    </svg>
  );
}

export default Wave01;
