import React from 'react'
import MainLanding from '../../components/Homepage/MainLanding/MainLanding'
import MainPartners from '../../components/Homepage/MainPartners/MainPartners'
import MainServices from '../../components/Homepage/MainServices/MainServices'
import MainStudents from '../../components/Homepage/MainStudents/MainStudents'
import MainAbout from '../../components/Homepage/MainAbout/MainAbout'
import MainFAQ from '../../components/Homepage/MainFAQ/MainFAQ'
import MainBlog from '../../components/Homepage/MainBlog/MainBlog'

function Home() {
  return (
    <div className=''>
        <MainLanding />
        <MainPartners />
        <MainServices />
        <MainStudents />
        <MainAbout />
        <MainFAQ />
        <MainBlog />
    </div>
  )
}

export default Home