import { Button, Drawer, Sidebar as SB, TextInput } from "flowbite-react";
import React, { useState } from "react";

import Container from "../Container/Container";

import { FaHandshake } from "react-icons/fa";
import { FaQuestion } from "react-icons/fa";
import { PiStudentBold } from "react-icons/pi";
import { FaBlog } from "react-icons/fa";
import { AiOutlineTeam } from "react-icons/ai";
import { IoIosMail } from "react-icons/io";
import { FaUniversity } from "react-icons/fa";
import { FaPhotoVideo } from "react-icons/fa";
import { IoLogOut } from "react-icons/io5";
import { IoMdInformationCircle } from "react-icons/io";

import Hamburger from "hamburger-react";
import { useNavigate } from "react-router";
import Cookies from "js-cookie";

function Sidebar() {
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => setIsOpen(false);

  const navigate = useNavigate();

  const handleLogout = () => {
    Cookies.remove("userToken"); // Delete the cookie
    navigate("/"); // Navigate to the home page
  };

  return (
    <>
      <SB
        aria-label="SB with multi-level dropdown example"
        className="bg-[#f9fafc] min-h-[calc(100vh-112px)] hidden lg:block"
      >
        <SB.Items className="">
          <SB.ItemGroup>
            <SB.Item href="/admin/students" icon={PiStudentBold}>
              სტუდენტები
            </SB.Item>
            <SB.Item href="/admin/blogs" icon={FaBlog}>
              ბლოგი
            </SB.Item>
            <SB.Item href="/admin/universities" icon={FaUniversity}>
              უნივერსიტეტი
            </SB.Item>
            <SB.Item href="/admin/partners" icon={FaHandshake}>
              პარტნიორები
            </SB.Item>
            <SB.Item href="/admin/team" icon={AiOutlineTeam}>
              ჩვენი გუნდი
            </SB.Item>
            <SB.Item href="/admin/aboutPhotos" icon={IoMdInformationCircle}>
              ჩვენ შესახებ
            </SB.Item>
            <SB.Item href="/admin/faq" icon={FaQuestion}>
              ხ.დ.შ.
            </SB.Item>
            <SB.Item href="/admin/mails" icon={IoIosMail}>
              წერილები
            </SB.Item>
          </SB.ItemGroup>
          <SB.ItemGroup>
            <Button className="w-full" onClick={handleLogout}>
              <IoLogOut className="w-5 h-5 mr-1" />
              გამოსვლა
            </Button>
          </SB.ItemGroup>
        </SB.Items>
      </SB>
      <div className="bg-[#f9fafc] lg:hidden py-2">
        <Container className="flex justify-between items-center">
          <h3 className="font-bold text-main-blue">ადმინის პანელი</h3>
          <Hamburger toggled={isOpen} toggle={setIsOpen} size={24} />
        </Container>
      </div>
      <Drawer open={isOpen} onClose={handleClose} className="w-full z-[100]">
        <Drawer.Header title="ადმინის პანელი" titleIcon={() => <></>} />
        <Drawer.Items>
          <SB
            aria-label="SB with multi-level dropdown example"
            className="[&>div]:bg-transparent [&>div]:p-0"
          >
            <div className="flex h-full flex-col justify-between py-2">
              <div>
                <SB.Items>
                  <SB.ItemGroup>
                    <SB.Item href="/admin/students" icon={PiStudentBold}>
                      სტუდენტები
                    </SB.Item>
                    <SB.Item href="/admin/blogs" icon={FaBlog}>
                      ბლოგი
                    </SB.Item>
                    <SB.Item href="/admin/universities" icon={FaUniversity}>
                      უნივერსიტეტები
                    </SB.Item>
                    <SB.Item href="/admin/partners" icon={FaHandshake}>
                      პარტნიორები
                    </SB.Item>
                    <SB.Item href="/admin/team" icon={AiOutlineTeam}>
                      ჩვენი გუნდი
                    </SB.Item>
                    <SB.Item href="/admin/aboutPhotos" icon={IoMdInformationCircle}>
                      ჩვენ შესახებ
                    </SB.Item>
                    <SB.Item href="/admin/faq" icon={FaQuestion}>
                      ხ.შ.დ.
                    </SB.Item>
                    <SB.Item href="/admin/mails" icon={IoIosMail}>
                      წერილები
                    </SB.Item>
                  </SB.ItemGroup>
                  <SB.ItemGroup>
                    <Button className="w-full" onClick={handleLogout}>
                      <IoLogOut className="w-5 h-5 mr-1" />
                      გამოსვლა
                    </Button>
                  </SB.ItemGroup>
                </SB.Items>
              </div>
            </div>
          </SB>
        </Drawer.Items>
      </Drawer>
    </>
  );
}

export default Sidebar;
