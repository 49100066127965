import React, { useState, useEffect } from "react";
import { Button, Navbar as Nav } from "flowbite-react";
import Container from "../Container/Container";
import { scrollToContact } from "../../../utils";
import { Link, useLocation } from "react-router-dom";
import LanguageSelector from "../../LanguageSelector/LanguageSelector";
import { useTranslation } from "react-i18next";

function Navbar() {
  const [navCollapsed, setNavCollapsed] = useState(false);
  const location = useLocation();
  const { t } = useTranslation();

  // Close navbar when navigating to a different page
  useEffect(() => {
    setNavCollapsed(false); // Close the navbar on route change
  }, [location]);

  return (
    <>
      {/* MOBILE NAVBAR */}
      <div className="xl:hidden">
        <div className="text-xs lg:text-sm p-2 flex justify-center bg-main-red text-white">
          {t("websiteIsUnderConstruction")}
        </div>
        <div
          className={`absolute w-full z-50 left-0 ${
            navCollapsed
              ? "bg-main-background border-b border-2 border-gray-400 rounded-b-3xl"
              : "bg-transparent"
          } lg:bg-transparent transition-all duration-300`}
        >
          <Container>
            <Nav
              fluid
              rounded
              className="justify-between bg-opacity-0 lg:bg-transparent"
            >
              <div
                className={`w-full grid ${
                  !navCollapsed ? "grid-cols-2" : "grid-cols-3"
                } md:flex md:justify-between`}
              >
                <Link to="/" onClick={() => setNavCollapsed(false)}>
                  <img
                    src="/images/logos/ell-logo.png"
                    className="mr-3 h-16 md:h-24"
                    alt="ell logo"
                  />
                </Link>
                <div
                  className={`${
                    !navCollapsed && "hidden"
                  } flex flex-col justify-center items-center`}
                >
                  <LanguageSelector />
                </div>
                <div className="flex items-center justify-end">
                  <Nav.Toggle
                    className="bg-white"
                    onClick={() => setNavCollapsed(!navCollapsed)}
                  />
                </div>
                <div className="hidden md:grid md:grid-cols-5">
                  <Link
                    to="/services"
                    className="flex text-sm justify-center items-center text-main-blue hover:text-cyan-700 font-semibold py-1"
                    onClick={() => setNavCollapsed(false)}
                  >
                    {t("services")}
                  </Link>
                  <Link
                    to="/universities"
                    className="flex text-sm justify-center items-center text-main-blue hover:text-cyan-700 font-semibold py-1"
                    onClick={() => setNavCollapsed(false)}
                  >
                    {t("universities")}
                  </Link>
                  <Link
                    to="/students"
                    className="flex text-sm justify-center items-center text-main-blue hover:text-cyan-700 font-semibold py-1"
                    onClick={() => setNavCollapsed(false)}
                  >
                    {t("students")}
                  </Link>
                  <Link
                    to="/blog"
                    className="flex text-sm justify-center items-center text-main-blue hover:text-cyan-700 font-semibold py-1"
                    onClick={() => setNavCollapsed(false)}
                  >
                    {t("blog")}
                  </Link>
                  <Link
                    to="/faq"
                    className="flex text-sm justify-center items-center text-main-blue hover:text-cyan-700 font-semibold py-1"
                    onClick={() => setNavCollapsed(false)}
                  >
                    {t("faq")}
                  </Link>
                  <Link
                    to="/about"
                    className="flex text-sm justify-center items-center text-main-blue hover:text-cyan-700 font-semibold py-1"
                    onClick={() => setNavCollapsed(false)}
                  >
                    {t("about")}
                  </Link>
                  <Link
                    to="/contact"
                    className="flex text-sm justify-center items-center text-main-blue hover:text-cyan-700 font-semibold py-1"
                    onClick={() => setNavCollapsed(false)}
                  >
                    {t("contact")}
                  </Link>
                  <div className="flex flex-col justify-center">
                    <Button color="main-red" onClick={scrollToContact} size={"sm"} className="h-10">
                      {t("contactUs")}
                    </Button>
                  </div>
                  <div className="flex items-center justify-center">
                    <LanguageSelector />
                  </div>
                </div>
              </div>
              <Nav.Collapse
                className={`justify-center items-center bg-darkBackground md:hidden lg:bg-transparent pb-2 ${
                  navCollapsed
                    ? "block bg-main-background rounded-b-lg "
                    : "hidden"
                }`}
              >
                <Link
                  to="/services"
                  className="flex justify-center items-center text-main-blue hover:text-cyan-700 font-semibold py-1"
                  onClick={() => setNavCollapsed(false)}
                >
                  {t("services")}
                </Link>
                <Link
                  to="/universities"
                  className="flex justify-center items-center text-main-blue hover:text-cyan-700 font-semibold py-1"
                  onClick={() => setNavCollapsed(false)}
                >
                  {t("universities")}
                </Link>
                <Link
                  to="/students"
                  className="flex justify-center items-center text-main-blue hover:text-cyan-700 font-semibold py-1"
                  onClick={() => setNavCollapsed(false)}
                >
                  {t("students")}
                </Link>
                <Link
                  to="/blog"
                  className="flex justify-center items-center text-main-blue hover:text-cyan-700 font-semibold py-1"
                  onClick={() => setNavCollapsed(false)}
                >
                  {t("blog")}
                </Link>
                <Link
                  to="/faq"
                  className="flex justify-center items-center text-main-blue hover:text-cyan-700 font-semibold py-1"
                  onClick={() => setNavCollapsed(false)}
                >
                  {t("faq")}
                </Link>
                <Link
                  to="/about"
                  className="flex justify-center items-center text-main-blue hover:text-cyan-700 font-semibold py-1"
                  onClick={() => setNavCollapsed(false)}
                >
                  {t("about")}
                </Link>
                <Link
                  to="/contact"
                  className="flex justify-center items-center text-main-blue hover:text-cyan-700 font-semibold py-1"
                  onClick={() => setNavCollapsed(false)}
                >
                  {t("contact")}
                </Link>
                <div className="hidden lg:flex justify-center">
                  <LanguageSelector />
                </div>
                <Button color="main-red" onClick={scrollToContact}>
                  {t("contactUs")}
                </Button>
              </Nav.Collapse>
            </Nav>
          </Container>
        </div>
      </div>

      {/* DESKTOP NAVBAR */}
      <div className="hidden xl:block">
        <div className="text-xs lg:text-sm p-2 flex justify-center bg-main-red text-white">
          {t("websiteIsUnderConstruction")}
        </div>
        <div
          className={`absolute w-full z-50 left-0 ${
            navCollapsed
              ? "bg-main-background border-b border-2 border-gray-400 rounded-b-3xl"
              : "bg-transparent"
          } lg:bg-transparent transition-all duration-300`}
        >
          <Container>
            <Nav
              fluid
              rounded
              className="justify-between bg-opacity-0 lg:bg-transparent"
            >
              <Link to="/" onClick={() => setNavCollapsed(false)}>
                <img
                  src="/images/logos/ell-logo.png"
                  className="mr-3 h-16 md:h-24"
                  alt="ell logo"
                />
              </Link>

              <div className="flex items-center justify-end">
                <Nav.Toggle
                  className="bg-white"
                  onClick={() => setNavCollapsed(!navCollapsed)}
                />
              </div>
              <div
                className={`lg:hidden grid grid-cols-${
                  !navCollapsed ? "2" : "3"
                } lg:grid-cols-2 w-full`}
              >
                <Link to="/" onClick={() => setNavCollapsed(false)}>
                  <img
                    src="/images/logos/ell-logo.png"
                    className="mr-3 h-16 md:h-24"
                    alt="ell logo"
                  />
                </Link>
                <div
                  className={`${
                    !navCollapsed && "hidden"
                  } flex flex-col justify-center items-center`}
                >
                  <LanguageSelector />
                </div>
                <div className="flex items-center justify-end">
                  <Nav.Toggle
                    className="bg-white"
                    onClick={() => setNavCollapsed(!navCollapsed)}
                  />
                </div>
              </div>
              <Nav.Collapse
                className={`justify-center items-center bg-darkBackground lg:bg-transparent pb-2 ${
                  navCollapsed
                    ? "block bg-main-background rounded-b-lg "
                    : "hidden"
                }
            `}
              >
                <Link
                  to="/services"
                  className="flex justify-center items-center text-main-blue hover:text-cyan-700 font-semibold py-1"
                  onClick={() => setNavCollapsed(false)}
                >
                  {t("services")}
                </Link>
                <Link
                  to="/universities"
                  className="flex justify-center items-center text-main-blue hover:text-cyan-700 font-semibold py-1"
                  onClick={() => setNavCollapsed(false)}
                >
                  {t("universities")}
                </Link>
                <Link
                  to="/students"
                  className="flex justify-center items-center text-main-blue hover:text-cyan-700 font-semibold py-1"
                  onClick={() => setNavCollapsed(false)}
                >
                  {t("students")}
                </Link>
                <Link
                  to="/blog"
                  className="flex justify-center items-center text-main-blue hover:text-cyan-700 font-semibold py-1"
                  onClick={() => setNavCollapsed(false)}
                >
                  {t("blog")}
                </Link>
                <Link
                  to="/faq"
                  className="flex justify-center items-center text-main-blue hover:text-cyan-700 font-semibold py-1"
                  onClick={() => setNavCollapsed(false)}
                >
                  {t("faq")}
                </Link>
                <Link
                  to="/about"
                  className="flex justify-center items-center text-main-blue hover:text-cyan-700 font-semibold py-1"
                  onClick={() => setNavCollapsed(false)}
                >
                  {t("about")}
                </Link>
                <Link
                  to="/contact"
                  className="flex justify-center items-center text-main-blue hover:text-cyan-700 font-semibold py-1"
                  onClick={() => setNavCollapsed(false)}
                >
                  {t("contact")}
                </Link>
                <LanguageSelector />
                <Button color="main-red" onClick={scrollToContact}>
                  {t("contactUs")}
                </Button>
              </Nav.Collapse>
            </Nav>
          </Container>
        </div>
      </div>
    </>
  );
}

export default Navbar;
