import React, { ReactElement, useState } from "react";
import Container from "../Shared/Container/Container";
import { Button, Modal } from "flowbite-react";

interface ServiceHorizontalPreviewProps {
  name: string;
  description: string;
  imageUrl: string;
}

function ServiceHorizontalPreview({
  name,
  description,
  imageUrl,
}: ServiceHorizontalPreviewProps) {
  const [openModal, setOpenModal] = useState(false);
  return (
    <>
      <div
        className="flex border-[1px] border-gray-300 bg-main-background rounded-2xl py-8 group hover:bg-main-red duration-100 cursor-pointer"
        onClick={() => setOpenModal(true)}
      >
        <Container className="flex flex-col items-center gap-4 px-2 lg:gap-8">
          <img src={imageUrl} alt="hero" className="lg:h-40 lg:w-40 w-36 h-40" />
          <div className="flex flex-col justify-between flex-1 gap-4 w-full">
            <h2 className="text-main-red font-bold text-lg text-center group-hover:text-white">
              {name}
            </h2>
          </div>
        </Container>
      </div>
      <Modal dismissible show={openModal} onClose={() => setOpenModal(false)} className="font-mtavvruli">
        <Modal.Header>
            <h2 className="font-bold text-main-blue">{name}</h2></Modal.Header>
        <Modal.Body>
          <div className="space-y-6" dangerouslySetInnerHTML={{__html: description}}>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="bg-main-red text-center"
            color={"main-red"}
            onClick={() => setOpenModal(false)}
          >
            დახურვა
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ServiceHorizontalPreview;
