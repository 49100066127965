import { Button, Label, Modal, Textarea, TextInput } from "flowbite-react";
import React, { useState } from "react";
import { Mail } from "../../../../interfaces/Mail";
import { FaBoltLightning } from "react-icons/fa6";
import { SiGmail } from "react-icons/si";
import HtmlEditor from "../../../../components/HtmlEditor/HtmlEditor";
import axios from "axios";

interface ViewMailModalProps {
  mail: Mail;
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

interface OpenGmailLinkProps {
  to: string; // Recipient email address
  subject: string; // Subject of the email
  body?: string; // Body content of the email (optional, with a default value)
  cc?: string; // Optional CC recipient
  bcc?: string; // Optional BCC recipient
}

const OpenGmailLink: React.FC<OpenGmailLinkProps> = ({
  to,
  subject,
  body = `
  პატივისცემით,

  ELL-ის ადმინისტრაცია
  +995 599 504012
  თბილისი, ვაჟა-ფშაველას 30ა, მესამე სართული, ოფისი 12
  `,
  cc,
  bcc,
}) => {
  // Use encodeURIComponent to encode special characters in the body

  // Create the Gmail compose URL with encoded parameters
  const createGmailUrl = () => {
    const baseUrl = "https://mail.google.com/mail/?view=cm&fs=1";
    const url = new URL(baseUrl);
    url.searchParams.append("to", to);
    url.searchParams.append("su", subject);
    url.searchParams.append("body", body);

    // Append optional fields if provided
    if (cc) {
      url.searchParams.append("cc", cc);
    }
    if (bcc) {
      url.searchParams.append("bcc", bcc);
    }

    return url.toString();
  };

  return (
    <Button>
      <a href={createGmailUrl()} className="flex" target="_blank" rel="noopener noreferrer">
        <SiGmail className="w-4 h-4 mr-1 mt-[2px]" />
        Gmail-ში გახსნა
      </a>
    </Button>
  );
};

const ViewMailModal: React.FC<ViewMailModalProps> = ({
  mail,
  openModal,
  setOpenModal,
}) => {
  // State for the reply content
  const [reply, setReply] = useState<string>(
    `პატივისცემით,
    <br />
    <br />
    ELL-ის ადმინისტრაცია
    <br />
    +995 599 504012
    <br />
    თბილისი, ვაჟა-ფშაველას 30ა, მესამე სართული, ოფისი 12`
  );

  // Function to handle sending an email using Brevo
  const handleSendReply = async () => {
    try {
      const response = await axios.post(
        "https://api.brevo.com/v3/smtp/email",
        {
          sender: { name: "Ell.ge", email: "hoopsmamba369@gmail.com" }, // Replace with your sender's email
          to: [{ email: mail.email }],
          subject: "პასუხი Ell-სგან",
          htmlContent: reply,
          cc: [{ email: "andriakhvichia2005@gmail.com" }],
        },
        {
          headers: {
            "Content-Type": "application/json",
            "api-key": process.env.REACT_APP_BREVO_API_KEY || "", // Ensure the API key is set correctly
          },
        }
      );
      console.log("პასუხი გაიგზავნა!", response.data);
      alert("პასუხი გაიგზავნა!");
      setOpenModal(false); // Close the modal after successful send
    } catch (error) {
      console.error("დაფიქსირდა შეცდომა:", error);
      alert("დაფიქსირდა შეცდომა. ცადეთ ახლიდან");
    }
  };

  return (
    <Modal
      dismissible
      show={openModal}
      onClose={() => setOpenModal(false)}
      className="font-mtavvruli"
    >
      <Modal.Header className="text-main-blue font-bold">
        ნახე <span className="text-main-red font-bold">{mail.name}</span>-ს
        წერილი
      </Modal.Header>
      <Modal.Body>
        <div className="space-y-6 w-full">
          <div className="flex flex-col gap-4">
            <div>
              <div className="mb-2 block">
                <Label htmlFor="name" value="სახელი" />
              </div>
              <TextInput
                id="name"
                type="text"
                sizing="md"
                value={mail.name}
                readOnly
              />
            </div>
            <div>
              <div className="mb-2 block">
                <Label htmlFor="email" value="ელ. ფოსტა" />
              </div>
              <TextInput
                id="email"
                type="text"
                sizing="md"
                value={mail.email}
                readOnly
              />
            </div>
            <div>
              <div className="mb-2 block">
                <Label htmlFor="phoneNumber" value="ტელეფონის ნომერი" />
              </div>
              <TextInput
                id="phoneNumber"
                type="text"
                sizing="md"
                value={mail.phoneNumber}
                readOnly
              />
            </div>
            <div>
              <div className="mb-2 block">
                <Label htmlFor="dateCreated" value="მოწერის დრო" />
              </div>
              <TextInput
                id="dateCreated"
                type="text"
                sizing="md"
                value={new Date(mail.dateCreated).toLocaleString()} // Format the date correctly
                readOnly
              />
            </div>
            <div>
              <div className="mb-2 block">
                <Label htmlFor="message" value="მესიჯი" />
              </div>
              <Textarea
                id="message"
                className="min-h-48"
                value={mail.message}
                readOnly
              />
            </div>
          </div>
        </div>
        {/* Reply Editor */}
        <div className="py-4">
          <h2 className="text-lg font-bold text-main-red py-2">
            პასუხის გაცემა
          </h2>
          <HtmlEditor
            onChange={(value) => setReply(value)}
            initialHtml={reply}
          />
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 my-4 ">
            <Button
              className="w-full"
              size="sm"
              onClick={handleSendReply} // Trigger the send email function
            >
              <FaBoltLightning className="w-4 h-4 mr-1 mt-[2px]" />
              სწრაფი პასუხი
            </Button>
            <OpenGmailLink
              to={mail.email}
              subject={`პასუხი მესიჯზე ELL-სგან`}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button color="failure" onClick={() => setOpenModal(false)}>
          დახურვა
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ViewMailModal;
