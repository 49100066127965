import React from "react";
import Container from "../../Shared/Container/Container";
import { Button, Card } from "flowbite-react";
import { HiOutlineArrowRight } from "react-icons/hi";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function MainAbout() {
  const { t } = useTranslation();
  return (
    <div
      className="relative bg-repeat animate-breathing" // Apply the animated background and breathing effect
      style={{
        backgroundImage: `url('/images/backgrounds/background-dots-01.png')`,
        backgroundSize: "150px 150px", // Initial size of the dots
      }}
    >
      <div className="flex flex-col gap-4 lg:gap-8 py-12 lg:py-16">
        <div>
          <h4 className="text-gray-600 text-center text-lg lg:text-xl font-bold">
            {t("whoAreWe")} {/* Translation for "ვინ ვართ ჩვენ?" */}
          </h4>
          <h3 className="text-main-blue text-center text-2xl lg:text-4xl font-bold">
            {t("aboutUs")} {/* Translation for "ჩვენ შესახებ" */}
          </h3>
        </div>
        <div className="relative flex items-center justify-center w-full h-[55vh] lg:h-[80vh] overflow-hidden my-8">
          <Container className="h-full flex flex-col justify-center relative">
            <Card
              className="max-w-xl relative rounded-xl h-full px-4 py-8 lg:py-16 sm:py-8 lg:pb-8 lg:pt-12 
              shadow-2xl lg:h-fit lg:-translate-y-4 z-50"
            >
              <h4 className="text-main-red font-bold sm:text-xl lg:text-xl">
                {t("moreThanAAgency")} {/* Translation for "მეტი, ვიდრე სასწავლებელი" */}
              </h4>
              <div dangerouslySetInnerHTML={{__html: t('learnMoreAboutUs')}}>
              </div>
              <h5 className="text-gray-500 text-sm lg:text-lg">
                {t("aboutUsDescription")} {/* Translation for "ELL 20 წელზე მეტია..." */}
              </h5>
              <Link to={"/about"}>
                <Button color={"main-red"} className="py-1 w-fit px-4">
                  {t("learnMore")} {/* Translation for "გაიგე მეტი" */}
                  <HiOutlineArrowRight className="ml-2 h-5 w-5" />
                </Button>
              </Link>
            </Card>
            <img
              src="/images/heros/hero-group-01.png"
              alt="Main"
              className="absolute hidden lg:flex right-0 object-cover xl:translate-x-48 2xl:translate-x-64"
            />
          </Container>
        </div>
      </div>
    </div>
  );
}

export default MainAbout;
