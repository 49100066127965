import { deleteDoc, doc, setDoc, updateDoc } from "firebase/firestore";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { Button, FileInput, Label, Modal, TextInput } from "flowbite-react";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import { object, string } from "yup";
import { db } from "../../../../firebase/firebaseConfig";
import { Blog } from "../../../../interfaces/Blog";
import HtmlEditor from "../../../../components/HtmlEditor/HtmlEditor";
import axios from "axios";

const { v4: uuidv4 } = require("uuid");

interface AddBlogModal {
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

function AddBlogModal({ openModal, setOpenModal }: AddBlogModal) {
  const [title, setTitle] = useState("");
  const [titleEn, setTitleEn] = useState(""); // For English title
  const [bodyHTML, setBodyHTML] = useState("");
  const [bodyHTMLEn, setBodyHTMLEn] = useState(""); // For English body
  const [createDate, setCreateDate] = useState(new Date().toISOString());
  const [imageUrl, setImageUrl] = useState("");

  const [file, setFile] = useState<File | null>(null);
  const [errorMessages, setErrorMessages] = useState<string[]>();
  const [buttonsDisabled, setButtonsDisabled] = useState(false);

  const navigate = useNavigate();

  let blogschema = object({
    title: string().required("სათაური აუცილებელია!"),
    titleEn: string().required("Title (English) is required!"),
    imageUrl: string().required("მთავარი სურათი აუცილებელია!"),
    createDate: string().required("შექმნის თარიღი აუცილებელია"),
    bodyHTML: string().required("ტანი აუცილებელია!"),
    bodyHTMLEn: string().required("Body (English) is required!"),
  });

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setFile(event.target.files[0]);
    }
  };

  const handleSave = async () => {
    try {
      let uploadedImageUrl = "";

      if (file) {
        const storage = getStorage();
        const storageRef = ref(storage, `images/${file.name}`);
        await uploadBytes(storageRef, file);
        uploadedImageUrl = await getDownloadURL(storageRef);
        setImageUrl(uploadedImageUrl);
      }

      await blogschema.validate({
        title,
        titleEn,
        bodyHTML,
        bodyHTMLEn,
        imageUrl: uploadedImageUrl,
        createDate,
      });

      const newBlog: Blog = {
        id: uuidv4(),
        title,
        titleEn,
        bodyHTML,
        bodyHTMLEn,
        createDate: createDate.toString(),
        imageUrl: uploadedImageUrl,
      };

      // Save the blog post to Firestore
      const blogDocRef = doc(db, "blogs", newBlog.id);
      await setDoc(blogDocRef, newBlog, { merge: true });
      await updateDoc(blogDocRef, newBlog as { [x: string]: any });

      // Post to Facebook using the Firebase function
      await postToFacebook(newBlog);

      setOpenModal(false);
      // navigate(0); // Refresh the page or navigate as needed
    } catch (e: any) {
      console.error(e);
      let messages = e.errors;
      setErrorMessages(messages);
    }
  };

  // Function to post blog content to Facebook
  const postToFacebook = async (blog: Blog) => {
    try {
      const response = await axios.post(
        "https://us-central1-ell-website.cloudfunctions.net/postToFacebook",
        {
          title: blog.title,
          imageUrl: blog.imageUrl,
          body: blog.bodyHTML,
        }
      );
      console.log("Successfully posted to Facebook:", response.data);
    } catch (error) {
      console.error("Failed to post to Facebook:", error);
    }
  };

  const handleDelete = async (blogId: string) => {
    setButtonsDisabled(true);
    try {
      const blogDocRef = doc(db, "blogs", blogId);
      await deleteDoc(blogDocRef);
      navigate(0); // Refresh the page or update the state to reflect changes
    } catch (error) {
      console.error("Error deleting document: ", error);
    }
    setButtonsDisabled(false);
  };

  const updateDate = () => {
    setCreateDate(new Date().toISOString());
  };

  return (
    <>
      <Modal
        dismissible
        show={openModal}
        onClose={() => setOpenModal(false)}
        className="font-mtavvruli"
      >
        <Modal.Header className="text-main-blue font-bold">
          დაამატე <span className="text-main-red">{"ახალი ბლოგი"}</span>
        </Modal.Header>
        <Modal.Body>
          <div className="space-y-6 w-full">
            <div className="flex flex-col gap-4">
              <div>
                <div className="mb-2 block">
                  <Label htmlFor="name" value="სათაური" />
                </div>
                <TextInput
                  id="name"
                  type="text"
                  sizing="md"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
              <div>
                <div className="mb-2 block">
                  <Label htmlFor="nameEn" value="Title (English)" />
                </div>
                <TextInput
                  id="nameEn"
                  type="text"
                  sizing="md"
                  value={titleEn}
                  onChange={(e) => setTitleEn(e.target.value)}
                />
              </div>
              <div className="flex flex-col gap-2">
                <div className="flex justify-between items-center">
                  <div className="mb-2 block">
                    <Label htmlFor="base" value="შექმნის თარიღი" />
                  </div>
                  <Button onClick={updateDate} color={"main-red"} size={"xs"}>
                    ჩაწერე დღევანდელი თარიღი
                  </Button>
                </div>
                <TextInput
                  id="base"
                  type="text"
                  sizing="md"
                  value={createDate}
                />
              </div>
              <HtmlEditor onChange={setBodyHTML} initialHtml={bodyHTML} />
              <HtmlEditor
                onChange={setBodyHTMLEn}
                initialHtml={bodyHTMLEn}
              />
              <div>
                <div className="mb-2 block">
                  <Label htmlFor="file" value="ატვირთე სურათი" />
                </div>
                <FileInput
                  id="file"
                  helperText={imageUrl ? imageUrl : "სურათი"}
                  onChange={handleFileChange}
                />
              </div>
            </div>
          </div>
          {errorMessages &&
            errorMessages?.map((message, index) => (
              <h3 key={index} className="text-main-red font-bold">
                {message}
              </h3>
            ))}
        </Modal.Body>
        <Modal.Footer>
          <Button
            color="main-red"
            onClick={() => setOpenModal(false)}
            disabled={buttonsDisabled}
          >
            დახურვა
          </Button>
          <Button color="gray" onClick={handleSave} disabled={buttonsDisabled}>
            შენახვა
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AddBlogModal;
