import React, { useEffect, useState } from "react";
import ServiceCard from "../../Cards/ServiceCard";
import Container from "../../Shared/Container/Container";
import Wave01 from "../../../assets/waves/Wave01";
import { Service } from "../../../interfaces/Service";
import {
  collection,
  DocumentData,
  getDocs,
  QueryDocumentSnapshot,
} from "firebase/firestore";
import { db } from "../../../firebase/firebaseConfig";
import { Spinner } from "flowbite-react";
import { useTranslation } from "react-i18next";

function MainServices() {
  const [services, setServices] = useState<Service[]>();
  const { i18n } = useTranslation(); // Initialize translation hook

  useEffect(() => {
    const fetchData = async () => {
      const querySnapshot = await getDocs(collection(db, "services"));
      const items = querySnapshot.docs.map(
        (doc: QueryDocumentSnapshot<DocumentData>) =>
          ({
            id: doc.id,
            ...doc.data(),
          } as Service)
      );
      setServices(items);
    };

    fetchData();
  }, []);

  if (!services) {
    return <Spinner />;
  }

  console.log(services.sort((a, b) => a.order - b.order));

  return (
    <div
      className="relative bg-repeat animate-breathing" // Add the breathing animation class
      style={{
        backgroundImage: `url('/images/backgrounds/background-dots-01.png')`,
        backgroundSize: "150px 150px", // Initial size of the dots
      }}
    >
      <Container className="z-[50] relative">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-2 lg:gap-8 pt-12 pb-28">
          {services &&
            services
              .sort((a, b) => a.order - b.order)
              .slice(0, 3)
              .map((service) => (
                <ServiceCard
                  key={service.id}
                  title={
                    i18n.language === "en"
                    ? service.nameEn
                    : service.name // Adjust based on the language
                  }
                  imageUrl={service.imageUrl}
                />
              ))}
        </div>
      </Container>
      <Wave01 className="absolute bottom-0 left-0 w-full" />
    </div>
  );
}

export default MainServices;
